/* hebrew */
@font-face {
  font-family: 'Varela Round';
  font-style: normal;
  font-weight: 400;
  src: local('Varela Round Regular'), local('VarelaRound-Regular'), url(https://fonts.gstatic.com/s/varelaround/v11/w8gdH283Tvk__Lua32TysjIfpcuPLdshZhVB.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
  font-display: swap;
}

/* vietnamese */
@font-face {
  font-family: 'Varela Round';
  font-style: normal;
  font-weight: 400;
  src: local('Varela Round Regular'), local('VarelaRound-Regular'), url(https://fonts.gstatic.com/s/varelaround/v11/w8gdH283Tvk__Lua32TysjIfqMuPLdshZhVB.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
  font-display: swap;
}

/* latin-ext */
@font-face {
  font-family: 'Varela Round';
  font-style: normal;
  font-weight: 400;
  src: local('Varela Round Regular'), local('VarelaRound-Regular'), url(https://fonts.gstatic.com/s/varelaround/v11/w8gdH283Tvk__Lua32TysjIfqcuPLdshZhVB.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}

/* latin */
@font-face {
  font-family: 'Varela Round';
  font-style: normal;
  font-weight: 400;
  src: local('Varela Round Regular'), local('VarelaRound-Regular'), url(https://fonts.gstatic.com/s/varelaround/v11/w8gdH283Tvk__Lua32TysjIfp8uPLdshZg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  font-family: 'Varela Round', sans-serif !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  background: #fbfbfb;
}

h1 {
  font-size: 5rem;
  text-align: center;
  font-weight: 600 !important;
}


h2 {
  font-weight: 600 !important;
  font-size: 2rem;
}

h3 {
  font-weight: 600 !important;
  font-size: 1.5rem;
}

h1,
h2,
h3,
h4,
h5 {
  padding-bottom: 1rem;

  @media (max-width: 768px) {
    padding-bottom: 0.5rem;
  }
}

h6 {
  padding-bottom: 0.25rem;
}

h4 {
  font-weight: 600;
  font-size: 1.5rem;
}

a {
  text-decoration: none;
}

li {
  text-align: inherit;
  list-style: none
}

hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.clickable {
  cursor: pointer;
}

.content {
  height: 85vh;
  margin: 57px auto 5vh auto;

  @media (max-width: 425px) {
    margin-top: 85px;
  }

  max-width: 980px;
  padding: 0.25rem 1rem;
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

.fa,
.fa::before,
.fa::after {
  font-family: FontAwesome !important;
}